import React from 'react'
import axios from 'axios'


import { useMode } from '../hooks/useMode'
import Spinner from './Spinner'

function Fact() {

    const [fact, setFact] = React.useState('')
    const [loading, setLoading] = React.useState(true)

    const url = useMode("prod");

    React.useEffect(() => {
        // Get date from local storage

        const date = localStorage.getItem('factDate')
        if (date) {
            if (date === new Date().toDateString()) {
                // If date is today, get fact from local storage
                console.log('Date is today')
                const fact = localStorage.getItem('fact')
                if (fact) {
                    // If fact exists in local storage, set fact
                    console.log('Fact exists in local storage')
                    setFact(fact)
                    setLoading(false)
                    return
                }
            }
        }

        axios.get(url + 'facts/today')
        .then(res => {
            console.log(res.data.text)
            setFact(res.data.text)
            // Store fact in local storage
            localStorage.setItem('fact', res.data.text)
            // Store date in local storage
            localStorage.setItem('factDate', new Date().toDateString())
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
        })
    }, [url])

  return (
    loading ?
        <Spinner />
        :
        
    <div className="fact-text">
        <p>{fact}</p>
        </div>

  )
}

export default Fact