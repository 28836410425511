import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'



import "./App.css";

import Home from "./pages/Home";
import History from './pages/History';

import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <Router>
      <Header />
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/history' element={<History />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
