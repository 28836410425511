import React from "react";

import logo from "../imgs/steely.png";
import logo1 from "../imgs/steely1.png";
import logo2 from "../imgs/steely2.png";
import logo3 from "../imgs/steely3.png";

import Fact from "../components/Fact";
import Footer from "../components/Footer";
function Home() {
  return (
    <>
      <div className="home">
        <div className="gallery">
          <img src={logo} className="App-logo" alt="logo" />
          <img src={logo1} className="App-logo" alt="logo" />
          <img src={logo2} className="App-logo" alt="logo" />
          <img src={logo3} className="App-logo" alt="logo" />
        </div>

        <Fact />  

      </div>
      

    </>
  );
}

export default Home;
