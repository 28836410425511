import React from "react";
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header">
      <div className="header-items">
        <h1>Twisted Today In Grateful Dead History</h1>
        <p>Facts about the Dead you couldn't believe, even if you tried!</p>
        <div className="buttons">
          <div className="button"> <Link to="/">Home</Link> </div>
          <div className="button"> <Link to="/history"> Archive</Link> </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
