import { useState } from "react";
import axios from "axios";

import { useMode } from "../hooks/useMode";
import Spinner from "../components/Spinner";

function History() {
  const [facts, setFacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = useMode("prod");

  const reverse = (array) => {
    let reversedArray = [];
    for (let i = array.length - 1; i >= 0; i--) {
      reversedArray.push(array[i]);
    }
    return reversedArray;

  }
  useState(async () => {
    await axios
      .get(url + "facts/")
      .then((res) => {
        console.log(res.data);
        const factsList = reverse(res.data);
        setFacts(factsList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="home">
      {loading ? (
        <Spinner />
      ) : (
          <div className="history-facts">
            {facts.map((fact) => (
              <div className="history-fact" key={fact._id}>
                <p>{fact.date}</p>
                <p>{fact.text}</p>
                <hr />
              </div>
            ))}
          </div>

      )}
    </div>
  );
}

export default History;
