

export const useMode = (mode) => {
    if (mode) {
        if (mode === 'dev') {
            return 'http://localhost:5000/api/'
        }
        else {
            return 'https://react-twisted-dead-api.onrender.com/api/'
        }
    }
    else {
        return 'https://react-twisted-dead-api.onrender.com/api/'
    }

    
}